.loginContainer {
    .authForm {
        background: $primary-color;
        padding: 40px;
        border-top: 0;
        color: $light-gray;
        border-radius: 20px;
        width: 420px;
        margin: 10% auto;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.050);
        h1 {
            font-size: 16px;
            text-align: center;
            padding: 8px 15px 10px;
            font-weight: 400;
        }
        .logo {
            max-width: 175px;
            margin: auto;
        }
        app-input-field {
            // margin-bottom: 15px;
            display: block;
        }
        button {
            width: 100%;
            padding: 6px;
            background-color: $maroon-color;
            border: none;
            justify-content: center;
            margin-top: 5px;
            .p-button-label {
                font-size: 15px;
                font-weight: 500;
            }
        }
    }
}

input {
    background: $primary-color;
    color: $light-gray;
    border: 1px solid $xl-light-gray;
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 3px;
    &.ng-touched {
        &.ng-invalid {
            border-color: $maroon-color;
        }
    }
    &:focus-visible {
        outline: none;
    }
}

.errorMassage {
    font-size: 12px;
    bottom: 15px;
    left: 10px;
    color: $maroon-color;
    position: relative;
}