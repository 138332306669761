.fileUpload {
    border: 2px dashed $light-silver-color;
    border-radius: 5px;
    min-height: 240px;
    position: relative;
    
    // background-image: url(../images/upload-image.png);
    // background-position: bottom;
    // background-repeat: no-repeat;
    // background-size: 330px;
    // background-color: $primary-color;
    margin-top: 30px;
    p-fileupload {
        .p-fileupload {
            .p-fileupload-buttonbar {
                display: flex;
                justify-content: flex-end;
                grid-gap: 10px;
                padding-right: 15px;
                .p-fileupload-choose {
                    position: absolute;
                    left: 0;
                    height: 100%;
                    display: flex;
                    width: 100%;
                    opacity: 0;
                    cursor: pointer;
                    z-index: 2;
                }
                p-button {
                    position: relative;
                    z-index: 9;
                    cursor: pointer;
                    button {
                        background-color: $light-blue-color;
                        border: none;
                        color: $primary-color;
                        display: flex;
                        align-items: center;
                        grid-gap: 10px;
                        padding: 5px 15px;
                        border-radius: 3px;
                        box-shadow: 0 0 10px $xl-light-gray;
                        margin-top: 10px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .p-fileupload-content {
        padding: 0;
        border: none;
    }
    .p-image {
        display: flex;
        justify-content: center;
        padding: 20px;
        img {
            max-width: 500px;
        }
    }
}