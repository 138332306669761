$primary-color: #ffffff;
$secondary-color: #000000;
$maroon-color: #ee3b39;
$danger-color: #fddddd;
$silver-color: #f4f7fa;
$light-silver-color: #dcdcdc;
$light-gray-1: #f1f1f1;
$light-gray-2: #E2E8F0;
$light-gray-3: #cccccc;
$light-gray-4: #f4f4f4;
$light-gray-5: #eeeeee;
$light-gray-6: #cdcdcd;
$light-gray-7: #e1e1e1;
$light-gray-8: #e0e0e0;
$light-gray: #666666;
$light1-gray: #979898;
$light2-gray: #6c757d;
$medium-gray: #333333;
$medium-gray-1: #1D1D1D;
$xl-light-gray: rgba(51, 51, 51, 0.2);
$blue-color: #0000ff;
$dark-blue-color: #007bff;
$light-blue-color: #3c8dbc;
$light-blue-color-1: #00ffff;
$light-blue-color-2: #66d1e6;
$light-blue-color-3: #dffaff;
$sky-blue-color: #061c1c;
$sky-light-blue: #add8e6;
$sky-light-blue-1: #f9faf9;
$success-color: #67c23a;
$hilight-color: #ffff85;
$maroon-ex-light-color: rgba(254, 242, 242, 0.95);
$toggle-color: #409eff;
$light-red-1: #f56c6c;
$light-red-2: #fbc4c4;
$light-red-3: #fef0f0;
$smoke-color: #f0f0f0;
$dark-gray: #ddd;
$red-color: #ff0000;
