/* You can add global styles to this file, and also import other style files */

@import '../src/assets/style/color-variable';
@import '../src/assets/style/auth';
@import '../src/assets/style/header';
@import '../src/assets/style/common-style';
@import '../src/assets/style/map';
@import '../src/assets/style/map_table';
@import '../src/assets/style/facility';
@import "primeicons/primeicons.css";
@import "leaflet/dist/leaflet.css";
// @import "/primeng/resources/themes/lara-light-blue/theme.css";
// @import "primeng/resources/primeng.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import '../src/assets/style/imageupload';
@import '~dripicons/webfont/webfont.css';
@import "~leaflet-draw/dist/leaflet.draw.css";

* {
    box-sizing: border-box;
}

body {
    font-family: "Roboto", sans-serif;
    margin: 0;
    background-color: $silver-color;
    overflow: hidden;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

img {
    max-width: 100%;
}

.logoutMenu {
    .p-component{
        ul{
            li{
                border: none;
            }
        }
    }
    .pi-sign-out{
        font-size: 12px;
    }
    .p-menuitem-link{
        font-size: 14px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top  : 1px solid #c5c5c5;
    }
}

