app-map-view {
    p-table {
        .p-datatable-wrapper {
            max-height: 330px;
            overflow: auto;
            table {
                thead {
                    position: sticky;
                    top: 0;
                    z-index: 9;
                }
                tbody{
                    td{
                        &.draggableRow {
                            cursor: grabbing;
                        }
                    }
                }
            }
        }
    }
}