app-settings {
    width: 100%;
}

.contentSection {
    app-add-device {
        width: 100%;
    }
}

input::rad {}

input[type=radio] {
    accent-color: $maroon-color;
}

.facilityDetails {
    padding: 0 0 2px;
    app-widgets {
        display: block;
        width: 100%;
        background-color: $primary-color;
        padding: 10px;
        padding-top: 0;
        border-top: 10px solid $primary-color;
        border-radius: 5px;
        padding-bottom: 0;
        min-height: 300px;
    }
    .facilityHead {
        display: flex;
        align-items: center;
        flex-flow: wrap;
        padding: 10px;
        border-radius: 10px;
        background-image: linear-gradient($light-silver-color, $primary-color);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.050);
        h2 {
            margin: 0;
            display: flex;
            align-items: center;
            flex: 1;
            grid-gap: 10px;
            .facilityIcon {
                background-color: $maroon-color;
                padding: 14px 12px;
                border-radius: 5px;
                display: flex;
                img {
                    filter: brightness(0) invert(1);
                    -webkit-filter: brightness(0) invert(1);
                }
            }
        }
    }
    .facilityList {
        width: 100%;
        display: table;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        app-button-field {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            margin-bottom: 10px;
            padding: 20px 0 0;
            button {
                background-color: $light-blue-color;
                border: none;
                padding: 10px;
                font-size: 15px;
            }
        }
        .docSaveBtn {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            margin-bottom: 10px;
            padding: 20px 0 0px;
            position: relative;
            z-index: 9;
            background-color: $primary-color;
            grid-gap: 10px;
            app-button-field {
                width: max-content;
                padding: 0;
            }
        }
        .facilityWidgetList {
            display: table-row;
            width: 100%;
            background-color: $silver-color;
            transition: 0.5s;
            &:hover {
                background: #cccfd345;
            }
            &.dataHeader {
                position: sticky;
                z-index: 99;
                .fInfo {
                    line-height: 45px;
                    font-weight: 600;
                    background-color: $light-silver-color;
                    white-space: nowrap;
                    border: none;
                    &:last-child {
                        width: 150px;
                    }
                }
            }
            &.selectedRow {
                background-color: $light-gray-2;
            }
            .fInfo {
                display: table-cell;
                padding: 5px;
                min-height: 55px;
                border-bottom: 10px solid $primary-color;
                vertical-align: middle;
                &:first-child {
                    padding-left: 10px;
                }
                p {
                    margin: 0;
                    text-transform: capitalize;
                    font-weight: 500;
                }
                span {
                    width: 52px;
                    height: 52px;
                    background-color: $light-gray-2;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 100%;
                        max-width: 40px;
                    }
                }
                p-breadcrumb {
                    nav {
                        padding: 0;
                        background: transparent;
                        border: none;
                        cursor: pointer;
                        ol {
                            li {
                                .p-menuitem-text {
                                    width: inherit;
                                    height: inherit;
                                    background: transparent;
                                    text-transform: capitalize;
                                    font-weight: 500;
                                    color: $secondary-color;
                                }
                                .p-menuitem-link {
                                    text-transform: capitalize;
                                    font-weight: 500;
                                    color: $secondary-color;
                                }
                            }
                        }
                    }
                }
            }
            .fActionBtnWraper {
                display: flex;
                align-items: center;
                grid-gap: 20px;
                font-size: 13px;
                font-weight: 500;
                padding-right: 25px;
                .pi {
                    font-size: 18px;
                    height: auto;
                    width: auto;
                    background-color: transparent;
                }
                .delAction {
                    cursor: pointer;
                    color: $maroon-color;
                    display: flex;
                    grid-gap: 5px;
                    opacity: 0.7;
                    &:hover {
                        font-weight: 600;
                        opacity: 1;
                    }
                }
                .editAction {
                    cursor: pointer;
                    color: $secondary-color;
                    display: flex;
                    grid-gap: 5px;
                    opacity: 0.7;
                    &:hover {
                        font-weight: 600;
                        opacity: 1;
                    }
                }
            }
        }
        &.facilitiesModuleWrapper {
            padding: 28px 39px 19px;
            border-radius: 9.96px;
            position: relative;
            margin-top: 15px;
            background-color: $primary-color;
            .closeElement {
                padding: 10px 0;
                position: absolute;
                right: 20px;
                top: 10px;
                i {
                    cursor: pointer;
                }
            }
        }
    }
    .actionBtnGroup {
        display: flex;
        grid-gap: 15px;
        align-items: center;
        button {
            border: none;
            padding: 10px 25px;
            background-color: transparent;
            display: flex;
            align-items: center;
            grid-gap: 6px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            flex-direction: row-reverse;
            color: $secondary-color;
            border-radius: 45px;
            &.current {
                background-color: $primary-color;
                border-radius: 50px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.050);
            }
            &:focus {
                outline: none !important;
                box-shadow: none !important;
            }
        }
        .addIcon {
            background-color: $maroon-color;
            color: $primary-color;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            cursor: pointer;
        }
        app-button-field {
            &.active {
                p-button {
                    .p-component {
                        background-color: $primary-color !important;
                        color: $secondary-color;
                    }
                }
            }
        }
    }
    .addFacilitiesHead {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 15px 0;
    }
    &.markerFacilities {
        width: 100%;
        margin-top: 0;
        padding: 0;
    }
}

.facilitiesModule {
    .dynamic-form {
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 10px 50px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        gap: 10px 50px;
        app-radio-button-field {
            .radioElement {
                display: flex;
                align-items: center;
                label {
                    white-space: nowrap;
                    min-width: 100px;
                    line-height: 30px;
                    margin-top: 3px;
                    margin-left: 5px;
                }
                input {
                    height: 20px;
                    width: 20px;
                }
            }
        }
        app-dropdown-field {
            flex: 0 0 48%;
            form {
                .p-dropdown {
                    width: 100%;
                    border-radius: 15px;
                    border: 1px solid $light-gray-2;
                    background-color: $primary-color;
                    height: 42px;
                }
            }
        }
        app-input-field {
            width: 100%;
        }
        app-multiselect {
            width: 100%;
        }
    }
}

// dropdown 
.p-dropdown-label {
    color: $secondary-color;
    font-size: 14px;
}

.filter-sidebar-summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 99;
    background: $sky-light-blue-1;
    padding: 15px;
    overflow: hidden;
    .filter-sidebar-total {
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 18px;
        color: $light1-gray;
        font-family: "Roboto", sans-serif;
    }
    .clearBtn {
        color: $light-red-1;
        text-decoration: none;
        background-color: $light-red-3;
        padding: 7px 15px;
        font-size: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 50px;
        font-weight: 600;
        gap: 5px;
        border: 1px solid $light-red-2;
        i {
            font-size: 12px;
        }
        &.disable {
            opacity: 0.4;
            cursor: not-allowed;
        }

        &:hover{
            background: $light-red-1;
            border-color: $light-red-1;
            color: $primary-color;
        }
    }
}

.filter-listingmodule {
    padding-bottom: 30px;
    padding: 0 15px;
    // max-height: calc(100vh - 385px);
    overflow: auto;
    .icons-listing {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 10px;
        p {
            font-weight: 700;
            cursor: pointer !important;
            color: $blue-color;
            margin: 0px !important;
        }
        .pi {
            color: $light1-gray;
            cursor: pointer;
        }
    }
    ul {
        list-style: none;
        li {
            padding: 12px 0;
            margin-left: 10px;
            cursor: pointer !important;
            color: $light1-gray;
        }
    }
}

.facilityDetails {
    p-accordion {
        .highlighted {
            .p-accordion-header-link {
                color: $secondary-color !important;
                background-color: $sky-light-blue !important;
                margin-top: 8px;
                border-radius: 5px;
                font-size: 13px;
                text-transform: capitalize;
            }
        }
    }
}

app-add-device-type {
    width: 100%;
}