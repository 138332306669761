app-google-map {
    width: 100%;
    display: flex;
    .mapWrapper {
        width: 100%;
        google-map {
            .map-container {
                width: 100% !important;
                height: 100% !important;
                background-color: $light-blue-color-1;
            }
        }
    }
}

// map control
.leaflet-bar {
    box-shadow: none;
    border-radius: 4px;
}

.hideGroupView {
    top: 99px;
    position: relative;
    left: -225px;
}

.hideTreeView,
.resetMap,
.hideTableView,
.hideGroupView,
.saveLocation {
    background-color: $primary-color;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
        background-color: $light-gray-1;
    }
    i {
        font-size: 16px;
    }
}

.resetMap {
    margin-bottom: 4px;
    position: absolute;
    left: -66px;
    bottom: 148px;
    border-radius: 2px;
    width: 40px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.hideTreeView {
    margin: 10px 0 0 10px;
    margin: 10px 0 0 10px;
    position: relative;
    left: -190px;
    top: 50px;
}

.hideTableView {
    position: relative;
    bottom: 10px;
}

.saveLocation {
    position: relative;
    top: 138px;
    left: -260px;
}

button {
    &.gm-control-active {
        &.gm-fullscreen-control {
            right: auto !important;
            bottom: 179px;
            top: auto !important;
        }
    }
}

.gmnoprint {
    &.gm-bundled-control {
        &.gm-bundled-control-on-bottom {
            right: auto !important;
            bottom: 140px !important;
            button {
                ~ {
                    .gmnoprint {
                        top: 42px !important;
                    }
                }
            }
        }
    }
}

// marker map css
.leaflet-control-fullscreen,
.leaflet-control-zoom {
    &.leaflet-bar {
        &.leaflet-control {
            margin-bottom: -2px;
            a {
                width: 38px !important;
                height: 38px !important;
                line-height: 38px !important;
            }
        }
    }
}

.leaflet-control-zoom {
    margin-bottom: 10px !important;
}

.treeControl {
    border-radius: 50px;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    &:hover,
    &:focus {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
    }
}

// custom dropdown
.app-device-list-filter {
    height: 100%;
    display: inline-block;
}

app-device-penal {
    display: block;
    max-height: calc(100vh - 224px);
    overflow: auto;
}

.map__marker-label {
    box-sizing: border-box;
    margin-top: 65px;
    border: 1px solid $secondary-color;
    border-radius: 4px;
    padding: 4px;
    background-color: $primary-color;
    white-space: pre;
    text-align: center;
    font-weight: 500;
    letter-spacing: 0.4px;
    max-width: 150px;
    min-width: 70px;
    text-wrap: wrap;
    // font-size: 12px !important;
    &.map__marker-label-highlight {
        outline: 4px solid #ff0;
        border-radius: 4px;
    }
}

.deviceListing {
    height: 100%;
    .deviceListRow {
        padding: 10px 15px;
        border-bottom: 1px solid $light-gray-3;
        &.highlightDeviceItems {
            background-color: $hilight-color;
        }
        h2 {
            margin: 0;
            font-size: 16px;
        }
        p {
            margin: 5px 0;
            font-size: 14px;
        }
        .switchContainer {
            margin: 5px 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            grid-gap: 10px;
            .batteryIcon {
                display: flex;
                align-items: center;
                grid-gap: 5px;
                white-space: nowrap !important;
                font-size: 13px;
            }
            .location {
                background-color: #67c23a;
                color: #fff;
                width: 24px;
                height: 24px;
                display: flex;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                min-width: 24px;
                i {
                    font-size: 12px;
                }
            }
        }
    }
}

.switch {
    width: 30px;
    min-width: 30px;
    height: 16px;
    &.selectAll {
        position: absolute;
        right: 15px;
        top: 22px;
    }
    .slider {
        &:before {
            height: 12px;
            width: 12px;
            left: 2.5px;
            bottom: 1.3px;
        }
    }
    input {
        &:checked {
            + {
                .slider {
                    &:before {
                        transform: translate(13px, 0px);
                        background-color: $primary-color !important;
                    }
                }
            }
        }
    }
}

// app map view
.mapViewWrapper {
    display: flex;
    width: 100%;
    grid-gap: 15px;
    .mapSearchList {
        .p-listbox {
            &.p-component {
                height: 100%;
                overflow: auto;
                .p-listbox-list-wrapper {
                    max-height: 315px !important;
                    li {
                        padding: 0;
                        min-height: 44px;
                        margin-bottom: 2px;
                        .selectedItem {
                            width: 100%;
                            padding: .75rem;
                            background-color: $silver-color;
                        }
                        .unSelectedItem {
                            padding: .75rem;
                        }
                        &:hover,
                        &.p-highlight:hover,
                        &.p-focus {
                            background-color: transparent;
                            color: $medium-gray-1;
                        }
                        &.p-highlight {
                            background-color: $silver-color;
                            color: $medium-gray-1;
                        }
                        i.pi {
                            border: 1px solid $maroon-color;
                            width: 20px;
                            height: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50px;
                            font-size: 10px;
                            min-width: 20px;
                            color: $maroon-color;
                        }
                        &.p-listbox-empty-message {
                            text-align: center;
                        }
                    }
                }
                .p-listbox-filter-container {
                    input {
                        padding: 6px 30px 6px 12px;
                    }
                    searchicon {
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        svg {
                            position: relative;
                            top: auto;
                            margin: auto;
                            right: auto;
                        }
                    }
                }
            }
        }
    }
    .googleMapDevice {
        display: flex;
        width: 100%;
        .leaflet-contextmenu {
            display: flex !important;
            grid-gap: 8px;
            flex-direction: column;
            padding: 10px 0;
            .leaflet-contextmenu-item {
                display: flex;
                align-items: center;
                grid-gap: 6px;
            }
        }
        .leaflet-tooltip-bottom {
            margin-top: 30px;
            &:before {
                left: 1px;
                margin-left: 0;
            }
        }
    }
}

// map navigation notification
.addressNotification {
    font-size: 16px;
    line-height: 26px;
}
// search filter
.mapViewWrapper {
    p-table {
        .p-datatable-header {
            .deviceSearchFilterWrapper {
                display: flex;
                grid-gap: 10px;
                align-items: center;
                input {
                    padding-left: 35px;
                }
                .pi {
                    cursor: pointer;
                }
            }
        }
    }
}
.filterList {
    .p-overlaypanel-content {
        padding: 10px 0;
        ul {
            display: flex;
            flex-direction: column;
            grid-gap: 2px;
            min-width: 140px;
            li{
                padding: 8px 10px;
                cursor: pointer;
                &:hover {
                    background-color: $light-gray-1;
                }
            }
        }
    }
}
.campusMapView {
    .map-container {
      &.googleMapDevice  {
        height: 100%;
      }
    }
  }